import { FunctionComponent } from 'react';
import { Button as MUIButton, ButtonProps } from '@mui/material';
import { ColorPalette, ColorPaletteValues } from 'shared/consts/colorPalette';
import { ContrastText } from 'shared/consts/contrastText';
import { Shadow } from 'shared/consts/shadow';

interface Props extends ButtonProps {
	color?: ColorPaletteValues;
}

export const Button: FunctionComponent<Props> = ({ color, variant = 'contained', sx, ...props }) => {
	return (
		<MUIButton
			variant={variant}
			sx={{
				'height': '60px',
				'borderRadius': '10px',
				'boxShadow': Shadow['1'],
				'margin': '0',
				'textTransform': 'initial',

				'fontWeight': 700,
				'fontSize': '18px',
				'lineHeight': '16px',
				'letterSpacing': '0.75px',

				'&.MuiButton-sizeSmall': {
					width: '150px',
				},
				'&.MuiButton-sizeMedium': {
					width: '329px',
				},
				'&.MuiButton-sizeLarge': {
					width: '411px',
				},
				'&.MuiButton-fullWidth': {
					width: '100%',
				},

				'&.MuiButton-text': {
					color: color ?? ColorPalette.royalBlue,
				},
				'&.MuiButton-contained': {
					backgroundColor: color ?? ColorPalette.royalBlue,
					color: color ? ContrastText[color] : ColorPalette.white,
				},
				'&.MuiButton-outlined': {
					'backgroundColor': 'transparent',
					'border': '1px solid transparent',
					'color': ColorPalette.astronaut,
					'boxShadow': 'none',
					'&.MuiButton-root:hover': {
						backgroundColor: ColorPalette.periwinkle,
						color: ColorPalette.astronaut,
						boxShadow: 'none',
					},
				},

				'&.MuiButton-root:hover': {
					backgroundColor: ColorPalette.astronaut,
					color: ContrastText[ColorPalette.astronaut],
					boxShadow: Shadow['1'],
				},
				'&.Mui-disabled': {
					backgroundColor: ColorPalette.periwinkle,
					color: ContrastText[ColorPalette.rockBlue],
					boxShadow: 'none',
				},
				...sx,
			}}
			{...props}
		>
			{props.children}
		</MUIButton>
	);
};
