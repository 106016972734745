import { FC, useState, useEffect, useMemo } from 'react';
import { Box, Typography, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getFtpSettings, saveFtpSettings } from 'entities/Integrations/api';
import { enqueueSnackbar } from 'notistack';
import { useMutation, useQuery } from 'react-query';
import { getCustomModels } from 'entities/CustomModel/api';
import { ModelStatuses } from 'entities/CustomModel/types';
import { Select } from 'shared/ui/Select';
import { FtpSettingsTypeEnum } from 'entities/Integrations/enums';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { Loader } from 'shared/ui/Loader';
import { Checkbox } from 'shared/ui/Checkbox';
import { ColorPalette } from 'shared/consts/colorPalette';
import { FtpSettings } from 'widgets/integrations/FtpSettings/Form/types';
import { FTPForm } from './FTPForm';
import { useCustomModelStore } from 'widgets/customModel/state';
import { SelectOption } from 'shared/ui/Select/types';
import { CategoriesList } from 'widgets/startRecognition/consts/CategoryList';
import { DefaultExportType } from 'entities/Company/types';
import { CategoryTypeEnum } from 'entities/Files/types';

export const FSForm: FC = () => {
	const { customModels, setCustomModels } = useCustomModelStore();
	const { t } = useTranslation();
	const [deleteAfterImport, setDeleteAfterImport] = useState(false);
	const [copyImportFtpToExportFtp, setCopyImportFtpToExportFtp] = useState(false);
	const [importFtpValues, setImportFtpValues] = useState<FtpSettings>();
	const [exportFtpValues, setExportFtpValues] = useState<FtpSettings>();
	const [selectedModelType, setSelectedModelType] = useState('');
	const [selectedInterval, setSelectedInterval] = useState('');
	const [selectedFileExtension, setSelectedFileExtension] = useState('');

	useQuery({
		queryKey: ['get/custom-models'],
		queryFn: () => getCustomModels(),
		onSuccess: (response) => {
			const filteredModels = response.data.filter((item) => item.status === ModelStatuses.trained);
			setCustomModels(filteredModels);
		},
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({ message: errorMessage, variant: 'error' });
		},
	});

	const { data: exportFtpSettingsData } = useQuery({
		queryKey: ['export-ftp-settings'],
		queryFn: () => getFtpSettings({ ftpSettingsType: FtpSettingsTypeEnum.generalExport }),
		retry: false,
	});

	const { data: importFtpSettingsData } = useQuery({
		queryKey: ['import-ftp-settings'],
		queryFn: () => getFtpSettings({ ftpSettingsType: FtpSettingsTypeEnum.generalImport }),
		retry: false,
	});

	const { mutate, isLoading } = useMutation({
		mutationKey: ['ftp-settings'],
		mutationFn: saveFtpSettings,
	});

	const optionsForModelSelect: SelectOption[] = useMemo(() => {
		const mappedCustomModels = customModels.map((item) => ({
			value: item._id,
			label: item.name,
			customLabel: (
				<Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
					<Typography>{item.name}</Typography>
					<Typography variant="body2" color={ColorPalette.baliHai}>
						{t('shared_texts.doc_type_custom')}
					</Typography>
				</Box>
			),
		}));
		const mappedStandardList: SelectOption[] = CategoriesList.filter(
			(item) => item.id !== CategoryTypeEnum.custom,
		).map((item) => ({
			value: item.id,
			label: t(item.label),
			customLabel: (
				<Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
					<Typography>{t(item.label)}</Typography>
					<Typography variant="body2" color={ColorPalette.baliHai}>
						{t(item.type)}
					</Typography>
				</Box>
			),
		}));
		return [...mappedStandardList, ...mappedCustomModels];
	}, [customModels]);

	useEffect(() => {
		if (importFtpSettingsData?.data) {
			setDeleteAfterImport(!!importFtpSettingsData.data.deleteAfterImport);
			setImportFtpValues({
				host: importFtpSettingsData.data.host,
				port: String(importFtpSettingsData.data.port ?? ''),
				path: importFtpSettingsData.data.path,
				protocol: importFtpSettingsData.data.protocol,
				username: importFtpSettingsData.data.username,
				password: importFtpSettingsData.data.password,
			});
			setSelectedInterval(importFtpSettingsData?.data.autoImport?.interval || '');
			setSelectedModelType(
				importFtpSettingsData?.data.autoImport?.customModelId ||
					importFtpSettingsData?.data.autoImport?.category ||
					'',
			);
		}
	}, [importFtpSettingsData]);

	useEffect(() => {
		if (exportFtpSettingsData) {
			setExportFtpValues({
				host: exportFtpSettingsData.data.host,
				port: String(exportFtpSettingsData.data.port ?? ''),
				path: exportFtpSettingsData.data.path,
				protocol: exportFtpSettingsData.data.protocol,
				username: exportFtpSettingsData.data.username,
				password: exportFtpSettingsData.data.password,
			});
			setSelectedFileExtension(exportFtpSettingsData?.data.autoExport || '');
		}
	}, [exportFtpSettingsData]);

	const handleSubmit = (type: FtpSettingsTypeEnum, values: FtpSettings) => {
		const standard = CategoriesList.find(
			(category) => category.id === (selectedModelType as CategoryTypeEnum),
		);

		const categoryPayload = standard
			? { category: selectedModelType as CategoryTypeEnum }
			: {
					category: CategoryTypeEnum.custom,
					customModelId: selectedModelType,
			  };

		const mutationValues = {
			...values,
			port: Number(values.port),
			ftpSettingsType: type,
			deleteAfterImport: type === FtpSettingsTypeEnum.generalImport ? deleteAfterImport : undefined,
			autoImport:
				selectedInterval && selectedModelType
					? {
							interval: selectedInterval,
							...categoryPayload,
					  }
					: undefined,
			autoExport: (selectedFileExtension as DefaultExportType) || undefined,
		};
		if (!((selectedInterval && !selectedModelType) || (selectedModelType && !selectedInterval))) {
			mutate(mutationValues, {
				onSuccess: () => {
					if (type === FtpSettingsTypeEnum.generalImport) {
						setImportFtpValues(values);
					} else if (type === FtpSettingsTypeEnum.generalExport) {
						setExportFtpValues(values);
					}
					setCopyImportFtpToExportFtp(false);
					enqueueSnackbar({
						message: t('integrations.integration_success'),
						variant: 'success',
					});
				},
				onError: (err) => {
					setCopyImportFtpToExportFtp(false);

					const defaultErrorMessage = t('integrations.integration_error');
					const errorMessage = getErrorMessage(err, defaultErrorMessage);
					enqueueSnackbar({
						message: errorMessage,
						variant: 'error',
					});
				},
			});
		}
	};

	return isLoading ? (
		<Loader />
	) : (
		<Box sx={{ display: 'flex', gap: '50px' }}>
			<Box>
				<Typography variant={'h5'} color={ColorPalette.astronaut} sx={{ marginBottom: '40px' }}>
					{t('integrations.import_folder')}
				</Typography>
				<FTPForm
					ftpSettingsData={importFtpValues}
					handleSubmit={(values) => handleSubmit(FtpSettingsTypeEnum.generalImport, values)}
				>
					<Select
						label={t('integrations.labels.import_interval') as string}
						placeholder={t('integrations.labels.never')}
						value={selectedInterval}
						onSelect={(value) => {
							setSelectedInterval(value as string);
							if (!value) {
								setSelectedModelType('');
							}
						}}
						options={optionsForRangeSelect || []}
						errorText={
							selectedModelType && !selectedInterval
								? (t('partners.placeholder.model_type') as string)
								: ''
						}
					/>
					<Select
						label={t('integrations.labels.import_model') as string}
						placeholder={t('partners.placeholder.model_type')}
						value={selectedModelType}
						onSelect={(value) => setSelectedModelType(value as string)}
						options={optionsForModelSelect || []}
						errorText={
							selectedInterval && !selectedModelType
								? (t('partners.placeholder.model_type') as string)
								: ''
						}
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={deleteAfterImport}
								onChange={() => {
									setDeleteAfterImport(!deleteAfterImport);
								}}
							/>
						}
						label={
							<Typography
								variant={'body2'}
								color={ColorPalette.baliHai}
								sx={{ marginLeft: '10px' }}
							>
								{t('sync_settings.ftp_form.delete_after_import')}
							</Typography>
						}
						sx={{ margin: 0, marginBottom: '40px' }}
					/>
				</FTPForm>
			</Box>

			<Box>
				<Typography variant={'h5'} color={ColorPalette.astronaut} sx={{ marginBottom: '40px' }}>
					{t('integrations.export_folder')}
				</Typography>
				<FTPForm
					ftpSettingsData={exportFtpValues}
					handleSubmit={(values) => handleSubmit(FtpSettingsTypeEnum.generalExport, values)}
				>
					<Select
						label={t('integrations.labels.export_type') as string}
						placeholder={'XLS, JSON, CSV, XML'}
						value={selectedFileExtension}
						onSelect={(value) => {
							setSelectedFileExtension(value as string);
						}}
						options={defaultTypeOptions || []}
					/>
					{importFtpValues?.host && (
						<FormControlLabel
							control={
								<Checkbox
									checked={copyImportFtpToExportFtp}
									onChange={() => {
										setCopyImportFtpToExportFtp(!copyImportFtpToExportFtp);
										setExportFtpValues(importFtpValues);
									}}
								/>
							}
							label={
								<Typography
									variant={'body2'}
									color={ColorPalette.baliHai}
									sx={{ marginLeft: '10px' }}
								>
									{t('sync_settings.ftp_form.copy_import')}
								</Typography>
							}
							sx={{ margin: 0, marginBottom: '40px' }}
						/>
					)}
				</FTPForm>
			</Box>
		</Box>
	);
};

const defaultTypeOptions = [
	{ value: '', label: '-' },
	{ value: DefaultExportType.XLSX, label: DefaultExportType.XLSX },
	{ value: DefaultExportType.XML, label: DefaultExportType.XML },
	{ value: DefaultExportType.JSON, label: DefaultExportType.JSON },
	{ value: DefaultExportType.CSV, label: DefaultExportType.CSV },
];

const optionsForRangeSelect: SelectOption[] = [
	{ value: '', label: 'integrations.labels.never' },
	{ value: '3 min', label: '3 min' },
	{ value: '5 min', label: '5 min' },
	{ value: '10 min', label: '10 min' },
	{ value: '15 min', label: '15 min' },
	{ value: '30 min', label: '30 min' },
	{ value: 'hour', label: 'integrations.labels.hour' },
	{ value: 'day', label: 'integrations.labels.day' },
];
